import "./about.css"
import ME from "../../assets/as.jpg"
import { FaPaintBrush } from "react-icons/fa"
import { BiCodeBlock } from "react-icons/bi"
import { AiFillDatabase } from "react-icons/ai"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useTranslation } from "react-i18next"

function About() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    })
    const { t } = useTranslation()
    const { heading1, heading2, frontend, backend, uiux, description } =
        t("about")

    return (
        <section id="about">
            <motion.h5
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                }}
            >
                {heading1}
            </motion.h5>
            <motion.h2
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                    delay: 0.3,
                }}
            >
                {heading2}
            </motion.h2>
            <div className="container about_container">
                <div className="about_me">
                    <div className="about_me_image">
                        <img className="my-pic" src={ME} alt="error" />
                    </div>
                </div>
                <div className="about_content">
                    <div className="about_cards" ref={ref}>
                        <motion.article
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{
                                delay: 2 * 0.5,
                                type: "spring",
                                ease: "easeOut",
                                duration: 0.75,
                            }}
                            className="about_card"
                        >
                            <BiCodeBlock className="about_card_icon" />
                            <h5>{frontend}</h5>
                            {/* <small>3+ Years Working</small> */}
                        </motion.article>
                        <motion.article
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{
                                delay: 3 * 0.5,
                                type: "spring",
                                ease: "easeOut",
                                duration: 0.75,
                            }}
                            className="about_card"
                        >
                            <AiFillDatabase className="about_card_icon" />
                            <h5>{backend}</h5>
                            {/* <small>3+ Years Working</small> */}
                        </motion.article>
                        <motion.article
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{
                                delay: 1 * 0.5,
                                type: "spring",
                                ease: "easeOut",
                                duration: 0.75,
                            }}
                            className="about_card"
                        >
                            <FaPaintBrush className="about_card_icon" />
                            <h5>{uiux}</h5>
                            {/* <small>3+ Years Working</small> */}
                        </motion.article>
                    </div>
                    <p>{description}</p>
                </div>
            </div>
        </section>
    )
}

export default About
