import "./header.css"
import CTA from "./CTA"
import HeaderSocial from "./HeaderSocial"
import Typewriter from "typewriter-effect"
import Circle from "./Circle"
import { motion } from "framer-motion"
import BottomHeader from "./BottomHeader"
import { useTranslation } from "react-i18next"
import { CgArrowLongRightR } from "react-icons/cg"

function Header() {
    const { t, i18n } = useTranslation()
    const { firstname, lastname, greetings, scrollTxt, subtitle1, subtitle2 } =
        t("header")
    return (
        <header id="header">
            <motion.div
                className="container header_container"
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                }}
                transition={{
                    duration: 1.5,
                    ease: "easeIn",
                }}
            >
                <h5 className="hello">{greetings}</h5>
                <h1>
                    <span
                        className={`first-name ${i18n.language === "hi" || i18n.language === "ru" ? "noto-serif-devanagari" : ""}`}
                    >
                        {firstname}
                    </span>{" "}
                    <span
                        className={`last-name ${i18n.language === "hi" || i18n.language === "ru" ? "noto-serif-devanagari" : ""}`}
                    >
                        {lastname}
                    </span>
                </h1>
                <Typewriter
                    options={{
                        autoStart: true,
                        loop: true,
                        delay: 40,
                        skipAddStyles: false,
                        wrapperClassName: "type",
                        // strings: ["Full Stack Developer", "UI/UX Designer"],
                        strings: [subtitle1, subtitle2],
                    }}
                />
                <CTA />
                <Circle />
                <HeaderSocial />
                <motion.a
                    href="#contact"
                    className="scroll_down"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    transition={{ delay: 2, duration: 1, ease: "easeIn" }}
                >
                    <span className="flex-center">
                    {scrollTxt}  <CgArrowLongRightR className="ms-2"/>
                    </span>
                </motion.a>
            </motion.div>
            <BottomHeader />
        </header>
    )
}

export default Header
