import hopeharbor_cover from "../../../assets/HopeHarbor.png"
import vizchat_cover from "../../../assets/vizchat.png"
import crypto_cover from "../../../assets/cryptoTracker.png"
import netflix_cover from "../../../assets/netflix3.png"
import whatsapp_cover from "../../../assets/chatbot-for-whatsapp-01.jpg"
import warehouse_cover from "../../../assets/warehouse-sc.png"
import telegram_cover from "../../../assets/Telegram-Bot.png"
import bloghub_cover from "../../../assets/bloghub.png"
import aora_cover from "../../../assets/Aora.png"
import npmCover_cover from "../../../assets/npmCover.png"

export const projectCover = {
    hopeharbor_cover,
    vizchat_cover,
    crypto_cover,
    netflix_cover,
    whatsapp_cover,
    warehouse_cover,
    telegram_cover,
    bloghub_cover,
    aora_cover,
    npmCover_cover,
}
