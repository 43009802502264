import "./skills.css"
import { data } from "./skillsData"
import SkillsCard from "./SkillsCard"
import { motion } from "framer-motion"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

function Skills() {
    const memoizedData = useMemo(() => data, [])
    const { t } = useTranslation()
    const { heading1, heading2 } = t("skills")

    return (
        <section id="skills">
            <motion.h5
                key={1}
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                }}
            >
                {heading1}
            </motion.h5>

            <motion.h2
                key={2}
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                    delay: 0.3,
                }}
            >
                {heading2}
            </motion.h2>
            <div className="container SkillContainer">
                {memoizedData.map((item, index) => (
                    <SkillsCard
                        key={index}
                        image={item.image}
                        title={item.title}
                        url={item.url}
                    />
                ))}
            </div>
        </section>
    )
}

export default Skills
