import { useTranslation } from "react-i18next"
import "./footer.css"

function Footer() {
    const { t } = useTranslation()
    const { description } = t("footer")
    return (
        <footer id="footer">
            <div className="footer-container">
                <p className="copyright">
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                        href="https://github.com/gurrudev"
                        target="_blank"
                        rel="noreferrer"
                    >
                        ĀŚUTOṢA.
                    </a>{" "}
                    {description}
                </p>
            </div>
        </footer>
    )
}

export default Footer
