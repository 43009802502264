import React from "react"
import Tooltip from "../tooltip/Tooltip"

const SkillsCard = ({ image, title }) => {
    return (
        <div className="skillsCard">
            <Tooltip text={title} position="top">
            <div className="skillsCard_img">
                <img src={image} alt="" />
            </div>
                {/* <h4>{title}</h4> */}
            </Tooltip>
        </div>
    )
}

export default SkillsCard
