/* eslint-disable no-unused-vars */
import "./navbar.css"
import logo1 from "../../assets/logo1.png"
import { GiHamburgerMenu } from "react-icons/gi"
import { RiCloseCircleLine } from "react-icons/ri"
import { useState } from "react"
import { useEffect } from "react"
import { HashLink } from "react-router-hash-link"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
// import { FiSun } from 'react-icons/fi'
// import { MdOutlineNightsStay } from 'react-icons/md'
import { Link } from "react-router-dom"
import { GrLanguage } from "react-icons/gr"

function Navbar() {
    const [theme, setTheme] = useState("dark-theme")
    const [menu, setMenu] = useState("close")
    const [navActive, setNavActive] = useState("#")
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [activeLanguage, setActiveLanguage] = useState("en")
    const { i18n } = useTranslation()
    const languages = [
        { code: "en-US", lang: "English" },
        { code: "hi", lang: "हिन्दी" },
        { code: "ja", lang: "日本人" },
        { code: "ru", lang: "Русский" },
        { code: "ar", lang: "عربي" },
        { code: "es", lang: "Español" },
        { code: "fr", lang: "Français" },
        { code: "de", lang: "Deutsche" },
        { code: "zh", lang: "中国人" },
    ]

    const { t } = useTranslation()
    const { home, about, projects, skills, contacts } = t("navbar")
    const toggleMenu = () => {
        menu === "close" ? setMenu("open") : setMenu("close")
    }
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }
    const toggleTheme = () => {
        theme === "dark-theme"
            ? setTheme("light-theme")
            : setTheme("dark-theme")
    }
    const handleLanguageClick = (language) => {
        i18n.changeLanguage(language)
        setActiveLanguage(language)
        setDropdownVisible(false)
    }
    useEffect(() => {
        document.body.className = theme
    }, [theme])
    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [i18n, i18n.language])
    const navClickClose = (val) => {
        switch (val) {
            case "#":
                setNavActive("#")
                setMenu("close")
                break
            case "about":
                setNavActive("#about")
                setMenu("close")
                break
            case "projects":
                setNavActive("#projects")
                setMenu("close")
                break
            case "skills":
                setNavActive("#skills")
                setMenu("close")
                break
            case "contact":
                setNavActive("#contact")
                setMenu("close")
                break
            default:
                setNavActive("#")
                break
        }
    }

    return (
        <motion.nav
            initial={{
                opacity: 0,
                top: "-3rem",
            }}
            animate={{
                opacity: 1,
                top: "1rem",
            }}
            transition={{
                duration: 0.5,
                ease: "easeInOut",
                type: "spring",
                stiffness: 300,
                damping: 20,
            }}
        >
            <Link to="/">
                <img src={logo1} alt="logo" className="logo" />
            </Link>
            <ul className={menu === "close" ? "navlink" : "navlink active"}>
                <button
                    onClick={() => {
                        navClickClose("#")
                    }}
                    className={navActive === "#" ? "nav-active" : null}
                >
                    <HashLink smooth to="#">
                        {home}
                    </HashLink>
                </button>
                <button
                    onClick={() => {
                        navClickClose("about")
                    }}
                    className={navActive === "#about" ? "nav-active" : null}
                >
                    <HashLink smooth to="#about">
                        {about}
                    </HashLink>
                </button>
                <button
                    onClick={() => {
                        navClickClose("projects")
                    }}
                    className={navActive === "#projects" ? "nav-active" : null}
                >
                    <HashLink smooth to="#projects">
                        {projects}
                    </HashLink>
                </button>
                <button
                    onClick={() => {
                        navClickClose("skills")
                    }}
                    className={navActive === "#skills" ? "nav-active" : null}
                >
                    <HashLink smooth to="#skills">
                        {skills}
                    </HashLink>
                </button>
                <button
                    onClick={() => {
                        navClickClose("contact")
                    }}
                    className={navActive === "#contact" ? "nav-active" : null}
                >
                    <HashLink smooth to="#contact">
                        {contacts}
                    </HashLink>
                </button>
            </ul>
            <div className="navbar-right-part">
                {/* <li onClick={toggleTheme}>
                    {theme === 'dark-theme' ? (
                        <FiSun />
                    ) : (
                        <MdOutlineNightsStay />
                    )}
                </li> */}
                <li onClick={toggleDropdown}>
                    <GrLanguage size={20} />
                </li>
                {dropdownVisible && (
                    <div className="language-dropdown">
                        <ul>
                            {languages.map((item) => (
                                <li
                                    key={item.code}
                                    className={`dropdown-items ${
                                        item.code === i18n.language
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleLanguageClick(item.code)
                                    }
                                >
                                    {item.lang}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <button onClick={toggleMenu} className="toggleMenuBtn">
                    {menu === "close" ? (
                        <GiHamburgerMenu size={25} />
                    ) : (
                        <RiCloseCircleLine size={25} />
                    )}
                </button>
            </div>
        </motion.nav>
    )
}

export default Navbar
