import { toolsLogo } from "../../helper/Images/Tools"

const {
    react,
    html,
    css,
    mongo,
    express,
    bootstrap,
    figma,
    tailwind,
    material,
    electron,
    ejs,
    git,
    ts,
    docker,
    py,
    angular,
    js,
    mysql,
    nextjs,
    firebase,
    nodejs,
    npm,
    php,
    solidity,
    redux,
    vite,
    postman,
    swagger,
    java,
    cpp,
    jupiter,
    appwrite,
    postgress,
} = toolsLogo

export const data = [
    {
        id: 1,
        image: react,
        title: "React",
        url: "www.google.co.in",
    },
    {
        id: 2,
        image: html,
        title: "HTML",
        url: "www.google.co.in",
    },
    {
        id: 3,
        image: css,
        title: "CSS",
        url: "www.google.co.in",
    },
    {
        id: 4,
        image: mongo,
        title: "MongoDB",
        url: "www.google.co.in",
    },
    {
        id: 5,
        image: express,
        title: "Express JS",
        url: "www.google.co.in",
    },
    {
        id: 6,
        image: figma,
        title: "Figma",
        url: "www.google.co.in",
    },
    {
        id: 7,
        image: bootstrap,
        title: "Bootstrap",
        url: "www.google.co.in",
    },
    {
        id: 8,
        image: ts,
        title: "TypeScript",
        url: "www.google.co.in",
    },
    {
        id: 9,
        image: js,
        title: "JavaScript",
        url: "www.google.co.in",
    },
    {
        id: 10,
        image: mysql,
        title: "MySql",
        url: "www.google.co.in",
    },
    {
        id: 11,
        image: nextjs,
        title: "NextJS",
        url: "www.google.co.in",
    },
    {
        id: 12,
        image: firebase,
        title: "Firebase",
        url: "www.google.co.in",
    },
    {
        id: 13,
        image: postgress,
        title: "Postgress",
        url: "www.google.co.in",
    },
    {
        id: 14,
        image: git,
        title: "Git",
        url: "www.google.co.in",
    },

    {
        id: 15,
        image: tailwind,
        title: "Tailwind CSS",
        url: "www.google.co.in",
    },
    {
        id: 16,
        image: material,
        title: "Material UI",
        url: "www.google.co.in",
    },
    {
        id: 17,
        image: ejs,
        title: "EJS",
        url: "www.google.co.in",
    },
    {
        id: 17,
        image: nodejs,
        title: "Node Js",
        url: "www.google.co.in",
    },
    {
        id: 18,
        image: npm,
        title: "NPM",
        url: "www.google.co.in",
    },
    {
        id: 19,
        image: php,
        title: "PHP",
        url: "www.google.co.in",
    },
    {
        id: 20,
        image: solidity,
        title: "Solidity",
        url: "www.google.co.in",
    },
    {
        id: 21,
        image: redux,
        title: "Redux",
        url: "www.google.co.in",
    },
    {
        id: 22,
        image: appwrite,
        title: "Appwrite",
        url: "www.google.co.in",
    },
    {
        id: 23,
        image: py,
        title: "Python",
        url: "www.google.co.in",
    },
    {
        id: 24,
        image: angular,
        title: "Angular",
        url: "www.google.co.in",
    },
    {
        id: 25,
        image: docker,
        title: "Docker",
        url: "www.google.co.in",
    },
    {
        id: 26,
        image: electron,
        title: "Electron",
        url: "www.google.co.in",
    },
    {
        id: 27,
        image: postman,
        title: "Postman",
        url: "www.google.co.in",
    },
    {
        id: 28,
        image: swagger,
        title: "Swagger",
        url: "www.google.co.in",
    },
    {
        id: 29,
        image: vite,
        title: "Vite",
        url: "www.google.co.in",
    },
    {
        id: 30,
        image: java,
        title: "Java",
        url: "www.google.co.in",
    },
    {
        id: 31,
        image: cpp,
        title: "C++",
        url: "www.google.co.in",
    },
    {
        id: 32,
        image: jupiter,
        title: "Jupiter",
        url: "www.google.co.in",
    },
]
