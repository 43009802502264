import { projectCover } from "../helper/Images/Projects"
import { useTranslation } from "react-i18next"
const {
    hopeharbor_cover,
    vizchat_cover,
    crypto_cover,
    netflix_cover,
    whatsapp_cover,
    warehouse_cover,
    telegram_cover,
    bloghub_cover,
    aora_cover,
    npmCover_cover,
} = projectCover

export const useProjectData = () => {
    const { t } = useTranslation()
    const {
        stringUtilityTs,
        aora_a,
        hopeHarbor,
        vizchat,
        cryptoTracker,
        blogHub,
        netflixClone,
        whatsappBot,
        inventoryManagement,
        telebot,
    } = t("projectsData")

    const data = [
        {
            image: npmCover_cover,
            title: "String Utility TS",
            github: "https://github.com/gurrudev/string-utility.git",
            live: "https://www.npmjs.com/package/string-utility-ts",
            des: stringUtilityTs,
            tags: ["Node JS", "TypeScript", "npm"],
        },
        {
            image: aora_cover,
            title: "Aora",
            github: "https://github.com/gurrudev/aora.git",
            live: "https://github.com/gurrudev/aora.git",
            des: aora_a,
            tags: [
                "React Native",
                "Node JS",
                "Tailwind CSS",
                "TypeScript",
                "Appwrite",
            ],
        },
        {
            image: hopeharbor_cover,
            title: "Hope Harbor",
            github: "https://github.com/gurrudev/HopeHarbor.git",
            live: "https://hopeharbor.netlify.app",
            des: hopeHarbor,
            tags: [
                "React JS",
                "Thirdweb",
                "Solidity",
                "Ethereum",
                "Node JS",
                "Tailwind CSS",
                "JavaScript",
            ],
        },
        {
            image: vizchat_cover,
            title: "VizChat",
            github: "https://github.com/gurrudev/viz-chat",
            live: "https://vizchat.vercel.app",
            des: vizchat,
            tags: [
                "Next JS",
                "TypeScript",
                "Tailwind CSS",
                "Node JS",
                "Stream IO",
                "Clerk",
            ],
        },
        {
            image: crypto_cover,
            title: "Crypto Tracker",
            github: "https://github.com/gurrudev/CryptoTracker.git",
            live: "https://cryptotrackerno1.netlify.app",
            des: cryptoTracker,
            tags: ["React JS", "Material UI", "CSS", "Node JS", "JavaScript"],
        },
        {
            image: bloghub_cover,
            title: "BlogHub",
            github: "https://github.com/gurrudev/Blog-Hub",
            live: "https://bloghubsite.netlify.app",
            des: blogHub,
            tags: [
                "React JS",
                "Tailwind CSS",
                "Node JS",
                "Mongo DB",
                "Redux",
                "JavaScript",
            ],
        },
        {
            image: netflix_cover,
            title: "Netflix Clone",
            github: "https://github.com/gurrudev/Netflix-Clone.git",
            live: "https://neflix-30554.web.app",
            des: netflixClone,
            tags: ["React JS", "Firebase", "CSS", "Node JS", "JavaScript"],
        },
        {
            image: whatsapp_cover,
            title: "Whatsapp Bot",
            github: "https://github.com/gurrudev/whatsapp-bot-ecommerce.git",
            live: "",
            des: whatsappBot,
            tags: ["Node JS", "JavaScript", "Meta"],
        },
        {
            image: warehouse_cover,
            title: "Warehouse Inventory",
            github: "https://github.com/gurrudev/inventory-management-system.git",
            live: "http://warehousejetha.infinityfreeapp.com",
            des: inventoryManagement,
            tags: ["PHP", "MySQL", "HTML", "CSS", "JavaScript"],
        },
        {
            image: telegram_cover,
            title: "Telegram Bot",
            github: "https://github.com/gurrudev/TeleBot.git",
            live: "https://telegram-bot-kznr.onrender.com/",
            des: telebot,
            tags: ["Node JS", "JavaScript"],
        },
    ]

    return { data }
}
