import "./contact.css"
import { MdOutlineMail } from "react-icons/md"
import { ImWhatsapp } from "react-icons/im"
import React, { useRef } from "react"
import emailjs from "emailjs-com"
import { motion } from "framer-motion"
import EarthCanvas from "../3D/Earth"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

function Contact() {
    const form = useRef()
    const { t } = useTranslation()
    const {
        heading1,
        heading2,
        inputPlaceholder1,
        inputPlaceholder2,
        inputPlaceholder3,
        submitBtnText,
        emailCardTitle,
        emailCardDescription,
        whatsappCardTitle,
        whatsappCardDescription,
    } = t("contact")

    const sendEmail = (e) => {
        e.preventDefault()

        const option = {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
        }

        emailjs
            .sendForm(
                "service_ung5els",
                "template_lkw3ou6",
                form.current,
                "WdhTAVRYi0HzvDYpJ"
            )
            .then((response) => {
                response.status === 200
                    ? toast.success("Message sent.", option)
                    : toast.error("Try again later.", option)
            })
            .catch((err) => {
                console.log(err)
            })

        e.target.reset()
    }

    return (
        <section id="contact">
            <motion.h5
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                }}
            >
                {heading1}
            </motion.h5>
            <motion.h2
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                    delay: 0.3,
                }}
            >
                {heading2}
            </motion.h2>
            <div className="contact_container">
                <div className="contact-firstcontent">
                    <div className="contact_options">
                        <article className="contact_option">
                            <MdOutlineMail className="contact_option-icon" />
                            <h4>{emailCardTitle}</h4>
                            <h5>{"pawarash000@gmail.com"}</h5>
                            <a
                                href="mailto:pawarash000@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                {emailCardDescription}
                            </a>
                        </article>
                        <article className="contact_option">
                            <ImWhatsapp className="contact_option-icon" />
                            <h4>{whatsappCardTitle}</h4>
                            <h5>{"+91 7620361771"}</h5>
                            <a
                                href=" https://wa.me/917620361771?text=Hello"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                {whatsappCardDescription}
                            </a>
                        </article>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <input
                            type="text"
                            name="name"
                            placeholder={inputPlaceholder1}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder={inputPlaceholder2}
                            required
                        />
                        <textarea
                            name="message"
                            rows="7"
                            placeholder={inputPlaceholder3}
                            required
                        ></textarea>
                        <button type="submit" className="btn btn-primary">
                            {submitBtnText}
                        </button>
                    </form>
                </div>
                <motion.div
                    initial={{ x: -1000 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="earthCanvasDiv"
                >
                    <EarthCanvas />
                </motion.div>
            </div>
        </section>
    )
}

export default Contact
