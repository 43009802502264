import CV from "../../assets/cv/ASHUTOSH's_RESUME.pdf"
import { FiDownload } from "react-icons/fi"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
function CTA() {
    const { t } = useTranslation()
    const { resumeBtn, letsTalkBtn } = t("header")
    return (
        <div className="cta">
            <motion.a
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                    duration: 2,
                    ease: "easeIn",
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                }}
                href={CV}
                download="ASHUTOSH's_RESUME.pdf"
                className="btn"
            >
                {resumeBtn}
                <FiDownload />
            </motion.a>
            <motion.a
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                    duration: 2,
                    ease: "easeIn",
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                }}
                href="#contact"
                className="btn-primary"
            >
                {letsTalkBtn}
            </motion.a>
        </div>
    )
}

export default CTA
