import react from "../../../assets/reactjs_tool.png"
import html from "../../../assets/html_tool.png"
import css from "../../../assets/css_tool.png"
import mongo from "../../../assets/mongodb_tool.png"
import express from "../../../assets/express-skills.png"
import bootstrap from "../../../assets/bootstrap_tool.png"
import git from "../../../assets/git_tool.png"
import figma from "../../../assets/figma_tool.png"
import tailwind from "../../../assets/tailwind_tool.png"
import material from "../../../assets/material_tool.png"
import ejs from "../../../assets/ejs.png"
import ts from "../../../assets/typescript_tool.png"
import js from "../../../assets/javascript_tool.png"
import mysql from "../../../assets/mysql-skills1.png"
import nextjs from "../../../assets/nextjs-svg.svg"
import vercel from "../../../assets/Vercel.png"
import firebase from "../../../assets/firebase-skills.png"
import nodejs from "../../../assets/nodejs-skills.png"
import npm from "../../../assets/npmskills.png"
import php from "../../../assets/php-skills.png"
import solidity from "../../../assets/Solidity-skills.png"
import redux from "../../../assets/redux_tool.png"
import appwrite from "../../../assets/Appwrite-skills.png"
import py from "../../../assets/py-skills.png"
import angular from "../../../assets/angular.svg"
import docker from "../../../assets/docker-skills.png"
import postgress from "../../../assets/PostgresSQL.png"
import electron from "../../../assets/Electron.png"
import postman from "../../../assets/Postman.png"
import swagger from "../../../assets/Swagger.png"
import vite from "../../../assets/Vite.js.png"
import java from "../../../assets/Java.png"
import cpp from "../../../assets/CPlusPlus.png"
import jupiter from "../../../assets/Jupyter.png"

export const toolsLogo = {
    docker,
    react,
    html,
    css,
    vite,
    postman,
    swagger,
    java,
    jupiter,
    cpp,
    mongo,
    express,
    bootstrap,
    figma,
    tailwind,
    material,
    postgress,
    electron,
    ejs,
    ts,
    js,
    mysql,
    nextjs,
    vercel,
    firebase,
    py,
    angular,
    git,
    nodejs,
    npm,
    php,
    solidity,
    redux,
    appwrite,
}
