import reactIcon from "../../../assets/react.png"
import htmlIcon from "../../../assets/html.png"
import cssIcon from "../../../assets/css.png"
import jsIcon from "../../../assets/js.png"
import reduxIcon from "../../../assets/redux.png"
import nodejsIcon from "../../../assets/nodejs.png"
import mongodbIcon from "../../../assets/mongodb.png"
import solidityIcon from "../../../assets/solidity.png"
import tailwindIcon from "../../../assets/tailwind.png"
import nextjsIcon from "../../../assets/nextjs.png"
import tsIcon from "../../../assets/ts.png"
import phpIcon from "../../../assets/php.png"
import muiIcon from "../../../assets/mui.png"
import mysqlIcon from "../../../assets/mysql.png"
import firebaseIcon from "../../../assets/firebase.png"
import thirdwebIcon from "../../../assets/thirdweb.jpg"
import metaIcon from "../../../assets/meta.jpg"
import appwriteIcon from "../../../assets/appwrite.png"
import npmIcon from "../../../assets/npm.png"

export const Icons = {
    reactIcon,
    htmlIcon,
    cssIcon,
    jsIcon,
    reduxIcon,
    nodejsIcon,
    mongodbIcon,
    solidityIcon,
    tailwindIcon,
    nextjsIcon,
    tsIcon,
    phpIcon,
    muiIcon,
    mysqlIcon,
    firebaseIcon,
    thirdwebIcon,
    metaIcon,
    appwriteIcon,
    npmIcon,
}
