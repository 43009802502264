import "./App.css"
import Navbar from "./components/navbar/Navbar"
import Header from "./components/header/Header"
import About from "./components/about/About"
import Skills from "./components/skills/Skills"
import Projects from "./components/projects/Projects"
import Contact from "./components/contact/Contact"
import Github from "./components/github_data/Github"
import { useState, useEffect } from "react"
import Loader from "./components/loader/Loader"
import StarsCanvas from "./components/3D/Stars"
import { ToastContainer } from "react-toastify"
import "./ReactTostify.css"
import Footer from "./components/footer/Footer"

function App() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const t = setTimeout(() => {
            setLoading(false)
        }, 1000)

        return () => {
            clearTimeout(t)
        }
    }, [])

    return loading ? (
        <Loader />
    ) : (
        <div className={"font-en"}>
            <ToastContainer theme="dark" />
            <Navbar />
            <Header />
            <About />
            <Projects />
            <Github />
            <Skills />
            <div style={{ position: "relative", zIndex: "0" }}>
                <Contact />
                <StarsCanvas />
            </div>
            <Footer />
        </div>
    )
}

export default App
