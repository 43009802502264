import { useMemo } from "react"
import GitHubCalendar from "react-github-calendar"
import { motion } from "framer-motion"
import "./github.css"
import { useTranslation } from "react-i18next"

function Github() {
    const colorTheme = useMemo(
        () => ({
            background: "#0b1d3b",
            text: "#ffffff",
            level4: "#125425",
            level3: "#228f41",
            level2: "#31a851",
            level1: "#66cc78",
            level0: "#2b4768",
        }),
        []
    )
    const { t } = useTranslation()
    const { heading1, heading2, heading3 } = t("github")

    return (
        <section className="github">
            <motion.h5
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                }}
            >
                {heading1}
            </motion.h5>
            <motion.h2
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                    delay: 0.3,
                }}
            >
                {heading2}
            </motion.h2>
            <div>
                <div className="git-padding">
                    <div className="git-stats">
                        <div style={{ display: "flex", gap: "2rem" }}>
                            <img
                                src="https://github-readme-stats.vercel.app/api?username=gurrudev&theme=yeblu&show_icons=true&hide_border=true&hide_title=true"
                                alt=""
                            />
                            <img
                                src="http://github-profile-summary-cards.vercel.app/api/cards/most-commit-language?username=gurrudev&theme=yeblu&"
                                alt=""
                            />
                            <img
                                src="https://github-readme-streak-stats.herokuapp.com?user=gurrudev&theme=yeblu&date_format=M%20j%5B%2C%20Y%5D&dates=737373&ring=d38234&fire=e1d038&stroke=00000000&currStreakNum=ff5700&currStreakLabel=A6A6A6&border=0000"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="container github_calendar">
                    <h2 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                        {heading3}
                    </h2>
                    <GitHubCalendar
                        style={{ marginBottom: "1rem" }}
                        username="gurrudev"
                        blockSize={15}
                        hideTotalCount={true}
                        blockMargin={8}
                        theme={colorTheme}
                        fontSize={16}
                    />
                </div>
            </div>
        </section>
    )
}

export default Github
