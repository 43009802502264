import React, { useState } from 'react';
import './tooltip.css'; // Add this for styling

const Tooltip = ({ text, position = 'top', children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className={`tooltip-box tooltip-${position}`}>
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
