import Lottie from "lottie-react"
import loader from "../../assets/loader.json"
import "./loader.css"

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-content">
                <Lottie
                    animationData={loader}
                    loop={true}
                    height={50}
                    width={50}
                />
            </div>
        </div>
    )
}

export default Loader
