import { BsLinkedin } from "react-icons/bs"
import { BsGithub } from "react-icons/bs"
import { FaDev } from "react-icons/fa"
import { BsStackOverflow } from "react-icons/bs"
import { motion } from "framer-motion"

const BottomHeader = () => {
    return (
        <motion.div
            className="bottom_header_social"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            transition={{ delay: 2, duration: 1, ease: "easeIn" }}
        >
            <a
                href="https://www.linkedin.com/in/gurrudev"
                target="_blank"
                rel="noreferrer"
            >
                <BsLinkedin />
            </a>
            <a
                href="https://github.com/gurrudev"
                target="_blank"
                rel="noreferrer"
            >
                <BsGithub />
            </a>
            <a
                href="https://stackoverflow.com/users/20042850/ashutosh-pawar"
                target="_blank"
                rel="noreferrer"
            >
                <BsStackOverflow />
            </a>
            <a href="https://dev.to/gurrudev" target="_blank" rel="noreferrer">
                <FaDev />
            </a>
        </motion.div>
    )
}

export default BottomHeader
